.root {
  --typography-heading-6-font-size: var(--typography-heading-5-font-size);
  --typography-heading-6-line-height: var(--typography-heading-5-line-height);

  @media screen and (--desktop-l) {
    --typography-heading-5-font-size: var(
      --typography-display-x-large-desktop-font-size
    );
    --typography-heading-5-line-height: var(
      --typography-display-x-large-desktop-line-height
    );
  }
}

.contentContainer {
  margin-top: var(--fourzerofour-content-margin-top);
}

.heading {
  --typography-heading-4-font-size: var(--typography-heading-3-font-size);
  --typography-heading-4-line-height: var(--typography-heading-3-line-height);
}

.para {
  color: var(--fourzerofour-para-color);
}

.imageContainer {
  height: 266px;
}

.multiColContentContainer {
  padding: 40px 16px 48px;
  border-bottom: 1px solid var(--color-secondary-muted);
}

.link {
  border-bottom: 1px solid var(--fourzerofour-border-color);
}

@media screen and (--mobile-l) {
  .contentContainer {
    margin-top: var(--fourzerofour-sm-content-margin-top);
  }
}

@media screen and (--desktop) {
  .heading {
    --typography-heading-3-font-size: var(--typography-heading-2-font-size);
    --typography-heading-3-line-height: var(--typography-heading-2-line-height);
  }

  .imageContainer {
    height: 100vh;
    width: 65.55%;
  }

  .multiColContentContainer {
    padding: 98px 80px 98px 48px;
    width: 34.44%;
    height: 100vh;
  }
}
